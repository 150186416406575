import requests from "@/utils/axios";
import baseApi from "@/api/baseApi";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/attendance/duty/balanceData",
    levelSelect(query: any) {
        return requests.post(this.modulesUrl + "/levelSelect", query);
    },
    formUpload(query: any) {
        const header = { "Content-type": "multipart/form-data" };
        return requests.post(this.modulesUrl + "/formUpload", query, { headers: header });
    },
    auditBatch(query: any) {
        return requests.post(this.modulesUrl + "/auditBatch", query);
    },
    submissionBatch(query: any) {
        return requests.post(this.modulesUrl + "/submissionBatch", query);
    },
    basicDel(query: any) {
        return requests.post(this.modulesUrl + "/basicDel", query);
    },
};
